<template>
 <BasicCard>
   <template slot="body">
     <div class="flex justify-between items-center">
       <div class="flex justify-start items-center">
         <div>
           <p class="text-red text-2xl font-bold">Rejected</p>
         </div>
       <div>
         <span v-for="(bill, index) in getDispenserDenominationsCashOnly"
               :key="`${index}-rejected`"
               class="ml-4 text-2xl">
           {{ denominationExpression(bill) }} =  <span class="text-red text-2xl font-bold">{{ getAmountForDenomination(bill)}}</span>
         </span>
         <span class="ml-4 text-2xl">Total: <span  class="text-2xl text-red ml-1 font-bold">{{ getTotalAmount }}</span></span>
       </div>
       </div>
       <div class="my-auto">
         <div class="text-right">
           <BasicButton :disabled="disableClearButton" :loading="loading" class="clear-button"  title="Clear" button-background-color="bg-red" @click="confirmModal=true" />
         </div>
       </div>
         <BasicModal v-if="confirmModal" @close="confirmModal=false">
           <template slot="body">
             <div class="w-400">
               <font-awesome-icon class="text-orange text-center ml-1 mr-2" size="8x" :icon="['far', 'exclamation-circle']"  />
               <h2 class="text-orange text-3xl mb-4 uppercase">Are you sure you want to clear the rejected dispenser?</h2>
               <div class="flex justify-evenly">
                 <BasicButton @click="confirmModal=false" class="w-1/3" title="NO" button-background-color="bg-gray-500" />
                 <BasicButton @click="clearRejectedBills" class="w-1/3" title="YES" />
               </div>
             </div>
           </template>
         </BasicModal>
         <BasicModal v-if="successModal" @close="successModal=false">
           <template slot="body">
             <div class="w-400">
               <font-awesome-icon class="text-orange text-center ml-1 mr-2" size="8x" :icon="['far', 'check']"  />
               <h2 class="text-orange text-4xl mb-4 uppercase">Rejected Dispenses Cleared</h2>
               <div class="flex justify-evenly">
               </div>
             </div>
           </template>
         </BasicModal>
     </div>
   </template>
 </BasicCard>
</template>
<script>
import TransactionLogDetail from "../../mixin/TransactionLogDetail";
import BasicCard from "../Helpers/Cards/BasicCard";
import BasicButton from "../Helpers/Buttons/BasicButton";
import BasicModal from "../Helpers/Modal/BasicModal";
import {mapActions, mapMutations, mapState} from "vuex";
import currency  from "currency.js";
import ToastMixin from "../../mixin/ToastMixin";

export default {
  name: "RejectedBills",
  data() {
    return {
      loading: false,
      confirmModal: false,
      successModal: false,
      errorFlag: false,
    }
  },
  mixins: [TransactionLogDetail, ToastMixin],
  components: {BasicCard, BasicButton, BasicModal},
  computed: {
    ...mapState("GeneralSettings", ["kioskDetails"]),
    ...mapState("CashMachine", ["billsRejected"]),
    ...mapState("ReceiptPrinting", ["API_KEYS_PRINT_LOG"]),
    getTotalAmount() {
      return currency(this.billsRejected.total).format();
    },
    disableClearButton() {
      return this.billsRejected.total === 0 || this.loading || this.errorFlag;
    },
    getErrorMessage() {
      return "NOT AVAILABLE"
    }
  },
  methods: {
    ...mapActions("CashMachine", ["resetBillsRejected"]),
    ...mapActions("ReceiptPrinting", ["printReceipt", "saveKioskDetails"]),
    ...mapMutations("ReceiptPrinting", ["setRejectedBillsBefore"]),
    async clearRejectedBills() {
      this.loading = true;
      try {
        this.saveKioskDetails(this.kioskDetails);
        this.setRejectedBillsBefore(this.mapRejectBins());
        this.printReceipt(this.API_KEYS_PRINT_LOG.REJECT_RECEIPT);
        await this.resetBillsRejected();
        this.showAndCloseSuccessModal();
      } catch(e){
        let message = e.response?.data?.message || e.response?.data?.error || "Not able to reset rejected bills dispenser.";
        this.errorToast(message);
        this.$emit("errorPage" ,message);
      } finally{
        this.loading = false;
        this.confirmModal = false;
      }
    },
    showAndCloseSuccessModal() {
      this.successModal = true;
      setTimeout(()=>{
        this.successModal = false;
        this.$emit("reload");
      }, 3000);
    },
    mapRejectBins() {
      return {
        ...this.billsRejected,
        denominationCounts:
            Object.entries(this.billsRejected.denominationCounts).map((denom) => {
            const total = this.getDispenserDenominationsCashOnly.find((bin) => bin.denomination === denom[0]).quantityMultiplier * denom[1];
            return {
              denomination: denom[0],
              count: this.billsRejected.denominationCounts[denom[0]],
              total
            };
        }),
      };
    },
    getAmountForDenomination( bill ) {
      return this.billsRejected.denominationCounts && currency((this.billsRejected.denominationCounts[bill.denomination] || 0) * bill.quantityMultiplier).format();
    },
    denominationExpression( bill ) {
      return this.billsRejected.denominationCounts && `${bill.label} x ${this.billsRejected.denominationCounts[bill.denomination] || 0}`;
    }
  },
}
</script>
<style scoped>
.clear-button{
  width: 120px;
  margin: 0.2em;
}
</style>
