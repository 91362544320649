<template>
  <div class="cash-machine-row bg-white grid grid-cols-9 border-b-gray-300 p-1 border-b-2 border-gray-300 inline-block align-middle">
    <div class="ml-3 text-2xl text-left text-orange my-auto">
      <p>{{ title }}</p>
    </div>
    <div class="col-span-3 ml-3 text-2xl my-auto">
      <div class="current-money-div flex justify-between my-auto">
        <div class="text-center">
          <p>Current Total:</p>
        </div>
        <div class="text-right">
          <span class="font-bold ">{{ currencyFormat(initialTotal) }}</span>
        </div>
      </div>
    </div>
    <div v-if="showNewValue" class="col-span-3 text-2xl my-auto">
      <div class="new-money-div flex justify-between my-auto">
        <div class="text-center">
          <p>New Total:</p>
        </div>
        <div class="text-right">
          <span class="font-bold">{{ currencyFormat(formValue) }}</span>
        </div>
      </div>
    </div>
    <div v-else class="col-span-3 text-2xl justify-start my-auto">
      <div v-if="edit">
        <span v-if="edit" class="text-b font-bold mr-1 text-center text-2xl tds-input-span my-auto">$</span>
        <input
            v-if="edit"
            v-model="value"
            class="tds-input text-center"
            :class="`${canUpdate ? 'bg-gray-200': 'bg-red-200'} p-2`"
            type="url" min="0" step="0.01" required
            @keypress="formatValue"
        />
      </div>
    </div>
    <div class="col-span-2 my-auto">
      <div v-if="edit" class="text-right flex justify-end m-1">
        <BasicButton class="w-1/2 mr-1" title="Cancel" @click="cancelUpdate" buttonBackgroundColor="bg-white" button-text-color="text-orange" active-color="bg-white" hover-color="bg-white"/>
        <BasicButton class="w-1/2" title="Update" @click="updateForm" :disabled="!canUpdate" />
      </div>
      <div v-else class="text-right flex justify-end m-1">
        <BasicButton class="w-3/6" title="Edit" @click="edit=true" />
      </div>
    </div>
  </div>
</template>

<script>
import BasicButton from "../Helpers/Buttons/BasicButton";
import currency from "currency.js";
export default {
  name: "CashMachineDispenserFormRow",
  components: { BasicButton },
  data(){
    return {
      edit: false,
      value: null,
      validModulus: false,
    }
  },
  props: {
      info: {
        type: Object,
        required: true
      },
    formValue:{
      type: Number
    }
  },
  computed:{
    title(){
      return this.info.title;
    },
    initialTotal(){
      return this.info.initialValue.total;
    },
    showNewValue(){
      return this.value && this.initialTotal !== this.formValue && !this.edit;
    },
    canUpdate(){
      return this.initialTotal != this.value && this.validModulus && this.value;
    },
  },
  methods:{
    formatValue($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || this.value.indexOf('.') != -1)) {
        $event.preventDefault();
      }
      if(this.value!=null && this.value.indexOf(".")>-1 && (this.value.split('.')[1].length > 1)){
        $event.preventDefault();
      }
    },
    setInitialToValue(){
      this.value = this.info.initialValue.total;
    },
    updateForm(){
      this.$emit('updateForm', {total: parseFloat(this.value), denomination: this.info.initialValue.denomination});
      this.edit = false;
      this.setWidthForNewDivs()
    },
    cancelUpdate(){
      this.edit = false;
    },
    currencyFormat(value){
      return currency(value).format();
    },
    setWidthForCurrentDivs(){
      let existingElements =  document.getElementsByClassName('current-money-div');
      const elementArray = Array.from(existingElements)
      if(elementArray.length >= 1){
        let max =  Math.max.apply(Math, elementArray.map(e=>{return e.clientWidth}));
        elementArray.map(e=>{ e.style.width = `${max +3}px`})
      }
    },
    setWidthForNewDivs(){
      let existingElements =  document.getElementsByClassName('new-money-div');
      const elementArray = Array.from(existingElements)
      if(elementArray.length >= 1){
        let max =  Math.max.apply(Math, elementArray.map(e=>{return e.clientWidth}));
        elementArray.map(e=>{ e.style.width = `${max +3}px`})
      }
    }
  },
  mounted(){
    this.setWidthForCurrentDivs();
  },
  watch: {
    info: {
      handler: function(){
        this.setInitialToValue();
      },
      deep: true
    },
    value() {
      this.validModulus = (Math.round(this.value * 100) % Math.round(this.info.modulusCheck * 100) === 0);
    },
    formValue(){
      setTimeout(()=>{
        this.setWidthForNewDivs()
      },100);
    }
  },
}
</script>

<style scoped>
.cash-machine-row:last-child{
  border: none !important;
}
.tds-input-span{
  position:relative;
  margin-right:-20px
}
.tds-input{
  max-width: 75%;
  padding-left:20px;
  text-align:left;
}
.current-money-div{
  width: fit-content;
}
.new-money-div{
  width: fit-content;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type=number] {
  -moz-appearance: textfield;
}
</style>
