<template>
  <PageTemplate>
    <template slot="nav">
      <BasicNav>
        <template slot="nav-left">
          <div class="flex justify-start" >
            <BackButton v-if="showHomeButton" @click="goHome" />
          </div>
        </template>
        <template slot="nav-center">
          <div class="flex justify-center">
            <h1 class="text-3xl">Cash/Coin Dispenser Access</h1>
          </div>
        </template>
      </BasicNav>
    </template>
    <template slot="body">
      <div v-if="loading || error">
        <LoadingCard v-if="loading" />
        <ErrorCard v-if="error" :errors="errors" message="Dispenser Access Page Not Available"/>
      </div>
      <div v-else class="grid grid-cols-1 mb-8">
        <DispenserAccessFormCard @homeButton="setHomeButton" @reload="loadPage" @errorPage="setErrors" >
          <template slot="rejectedBills">
            <RejectedBills @reload="loadPage" @errorPage="setErrors" />
          </template>
        </DispenserAccessFormCard>
      </div>
    </template>
  </PageTemplate>
</template>
<script>
import PageTemplate from "../../components/Helpers/Pages/PageTemplate";
import BasicNav from "../../components/Helpers/Navs/BasicNav";
import {mapActions, mapGetters, mapMutations} from "vuex";
import DispenserAccessFormCard from "../../components/Cards/DispenserAccessFormCard";
import BackButton from "../../components/Helpers/Buttons/BackButton";
import ErrorCard from "../../components/Helpers/Pages/ErrorCard";
import RejectedBills from "../../components/Cards/RejectedBills";
import LoadingCard from "../../components/Helpers/Loading/LoadingCard";
import ToastMixin from "../../mixin/ToastMixin";

export default {
  name: "DispenserAccess",
  components: {LoadingCard, ErrorCard, BackButton, DispenserAccessFormCard, BasicNav, PageTemplate, RejectedBills},
  mixins: [ToastMixin],
  computed: {
    ...mapGetters("CashMachine", ["cashDispenseTotalResponse"]),
  },
  data(){
    return {
      showHomeButton :true,
      loading: true,
      error: false,
      errors: [],
    }
  },
  methods: {
    ...mapActions("CashMachine", ["getCashDispenseTotal", "getBillsRejected", "dispenserAccessPage"]),
    ...mapMutations("CashMachine", ["setBillsRejected", "setCashDispenseTotal"]),
    goHome(){
      this.$router.push({name:'Home'})
    },
    setHomeButton(value){
      this.showHomeButton = value
    },
    setErrors(error){
      this.error = true;
      this.errors = [...this.errors, error];
    },
    async loadPage(){
      this.loading = true;
      this.errors = [];
      let response = await this.dispenserAccessPage();
      const rejected = response.filter(result => result.status === 'rejected').map(result => result.reason);
      const fulfilled = response.filter(result => result.status === 'fulfilled').map(result => result.value);
      if(rejected.length > 0){
        this.error = true;
        rejected.map(e =>{
          let message = e.response?.data?.message || e.response?.data?.error || "Error fetching content for page.";
          this.errors.push(message);
          this.errorToast(message);
        })
      }else{
        await this.setBillsRejected(fulfilled[0].data);
        await this.setCashDispenseTotal(fulfilled[1].data);
      }
      this.loading = false;
    }
  },
  created(){
    this.loadPage();
  }
}
</script>

<style scoped>

</style>