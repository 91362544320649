<template>
  <div class="mw-100">
    <div>
    <BasicCard title="">
      <template slot="body">
        <div v-if="loading">
          <font-awesome-icon spin class="text-orange text-center " size="7x" :icon="['fas', 'spinner']"  />
        </div>
        <div v-else :key="rerender">
        <CashMachineDispenserFormRow v-for="(obj,key) in cashInfo" :info="obj" :formValue="getFormValue(key)" @updateForm="updateForm"  :key="`row-${key}`"/>
        </div>
      </template>
    </BasicCard>
      <div class="mt-5">
        <slot name="rejectedBills"></slot>
      </div>
      <div class="summary-div mt-5">
        <BasicCard>
          <template slot="body">
            <div class="flex justify-between align-middle">
              <div class="ml-4 text-2xl text-left text-orange my-auto">
                <p class="uppercase">TOTAL CURRENT CASH/COIN</p>
              </div>
              <div class="ml-4 text-3xl my-auto mr-4">
                <p>{{ currencyFormat(getTotal) }}</p>
              </div>
            </div>
          </template>
        </BasicCard>
      </div>
    <div class="modals">
      <div v-if="!isInitialSameAsForm" class="flex justify-between p-3 m-3">
        <BasicButton class="w-1/3" title="RESET & START OVER" @click="resetModal=true" button-background-color="bg-gray"></BasicButton>
        <BasicButton class="w-1/3" title="COMPLETE & PRINT RECEIPT" @click="confirmModal=true"></BasicButton>
      </div>
      <div v-else class="flex justify-end p-3 m-3">
        <BasicButton class="w-1/3" title="Print Reciept" @click="snapShot" button-background-color="bg-orange" :loading="loadingSnapShot"></BasicButton>
      </div>
      <BasicModal v-if="resetModal" @close="resetModal=false">
        <template slot="body">
        <div class="w-400">
            <font-awesome-icon class="text-orange text-center ml-1 mr-2" size="8x" :icon="['far', 'exclamation-circle']"  />
          <h2 class="text-orange text-3xl mb-4 uppercase">Would you like to reset and start over?</h2>
          <div class="flex justify-evenly">
            <BasicButton @click="resetModal=false" class="w-1/3" title="NO" button-background-color="bg-gray-500" ></BasicButton>
            <BasicButton @click="resetForm" class="w-1/3" title="YES"></BasicButton>
          </div>
        </div>
        </template>
      </BasicModal>
      <BasicModal v-if="confirmModal" @close="confirmModal=false">
        <template slot="body">
          <div class="w-400">
            <font-awesome-icon class="text-orange text-center ml-1 mr-2" size="8x" :icon="['far', 'exclamation-circle']"  />
            <h2 class="text-orange text-3xl mb-4 uppercase">Are you finished?</h2>
            <div class="flex justify-evenly">
              <BasicButton @click="confirmModal=false" class="w-1/3" title="NO" button-background-color="bg-gray-500" />
              <BasicButton @click="submitForm" class="w-1/3" title="YES" />
            </div>
          </div>
        </template>
      </BasicModal>
      <BasicModal v-if="successModal" @close="successModal=false">
        <template slot="body">
          <div class="w-400">
            <font-awesome-icon class="text-orange text-center ml-1 mr-2" size="8x" :icon="['far', 'check']"  />
            <h2 class="text-orange text-4xl mb-4 uppercase">Update Complete Printing Receipt...</h2>
            <div class="flex justify-evenly">
            </div>
          </div>
        </template>
      </BasicModal>
    </div>
    </div>
  </div>
</template>
<script>

import CashMachineDispenserFormRow from "../CashMachine/CashMachineDispenserFormRow";
import BasicCard from "../Helpers/Cards/BasicCard";
import {mapGetters, mapActions, mapState} from "vuex";
import BasicModal from "../Helpers/Modal/BasicModal";
import BasicButton from "../Helpers/Buttons/BasicButton";
import {find} from "lodash";
import currency from "currency.js";
import ToastMixin from "../../mixin/ToastMixin";

export default {
  name: "DispenserAccessFormCard",
  components: { BasicButton, BasicModal, CashMachineDispenserFormRow , BasicCard},
  mixins: [ToastMixin],
  data(){
    return {
      resetModal: false,
      confirmModal: false,
      loading: false,
      loadingSnapShot: false,
      successModal: false,
      errorFetching: false,
      rerender: 0,
      form: {
        FIVE: null,
        ONE: null,
        QUARTER: null,
        NICKEL: null,
        PENNY:null
      },
      cashInfo : {
        ONE:{
          title: "$1 Bills",
          initialValue: {},
          modulusCheck: 1,
        },
        FIVE:{
          title: "$5 Bills",
          initialValue: {},
          modulusCheck: 5,
        },
        QUARTER:{
          title: "Quarters",
          initialValue: {},
          modulusCheck: 0.25,
        },
        NICKEL:{
          title: "Nickels",
          initialValue: {},
          modulusCheck: 0.05,
        },
        PENNY:{
          title: "Pennies",
          initialValue: {},
          modulusCheck: 0.01,
        },
      }
    }
  },
  computed: {
    ...mapGetters("CashMachine", [ "cashDispenseTotalResponse"]),
    ...mapState("GeneralSettings", ["kioskDetails"]),
    ...mapState("ReceiptPrinting", ["printError", "API_KEYS_PRINT_LOG"]),
    getBins(){
      return this.cashDispenseTotalResponse.bins;
    },
    isInitialSameAsForm(){
      let flag = true;
      for (const property in this.form) {
          if(this.form[property] !== this.cashInfo[property].initialValue.total){
            flag = false;
            this.$emit('homeButton', false);
          }
      }
      this.$emit('homeButton', flag);
      return flag;
    },

    getTotal(){
      return this.cashDispenseTotalResponse.total;
    }
  },
  watch: {
    printError() {
      if(this.printError.active === true) {
        this.errorToast(this.printError.message)
      }
    }
  },
  methods: {
    ...mapActions('CashMachine', ['getCashDispenseTotal', "resetCashDispenser"]),
    ...mapActions('ReceiptPrinting', ['saveDispenseTotalBefore', 'saveDispenseTotalAfter', 'printReceipt', 'saveKioskDetails']),
    updateForm({denomination, total}) {
      this.form[denomination] = total;
    },
    currencyFormat(value){
      return currency(value).format()
    },
    setValues() {
      this.setFormValuesToInitialState();
      this.setCashInfoValuesToInitialState();
    },
    successModalTimeout(){
      this.successModal = true;
      try{
        this.printReceipt(this.API_KEYS_PRINT_LOG.DISPENSER_RECEIPT);
      }catch(e){
        let message = e.response?.data?.message || e.response?.data?.error || "Unable to print reset confirmation.";
        this.errorToast(message)
      }
      setTimeout(()=>{
        this.$emit("reload");
      }, 3000)
    },
    resetForm() {
      this.rerender++;
      this.setValues();
      this.resetModal = false;
    },
    async submitForm() {
      let binId = 1
      this.loading = true;
      const dispenseModel = { bins: [] };
      Object.keys(this.form).forEach((key) => {
        dispenseModel.bins.push({
          quantity: currency(this.form[key]).divide(this.cashInfo[key].modulusCheck),
          denomination: key,
          binId,
        });
        binId++;
      })
      try{
        this.saveKioskDetails(this.kioskDetails);
        this.saveDispenseTotalBefore(this.cashDispenseTotalResponse);
        await this.resetCashDispenser(dispenseModel);
        this.saveDispenseTotalAfter(this.cashDispenseTotalResponse);
        this.confirmModal = !this.confirmModal;
        this.successModalTimeout();
      }catch(e){
        this.errorToast(`Unable to update Dispenser`);
        this.$emit("errorPage", "Unable to update Dispenser");
      }
    },
    getFormValue(denomination) {
      return this.form[denomination];
    },
    setFormValuesToInitialState() {
      for (const property in this.cashInfo) {
        if(find(this.getBins, {denomination:property})){
          this.form[property] = find(this.getBins, {denomination:property}).total
        }else{
          this.form[property] = 0;
        }
      }
    },
    nullValueForInitial(property){
        return {
          binId: null,
          denominationType: '',
          denomination: property,
          quantity: '',
          total: 0.00 ,
          lastResetDateTime: '',
          lastDispenseDateTime: ''
        }
    },
    setCashInfoValuesToInitialState() {
      for (const property in this.cashInfo) {
        if(find(this.getBins, {denomination:property})){
          this.cashInfo[property]['initialValue'] = find(this.getBins, {denomination:property})
        }else{
          this.cashInfo[property]['initialValue'] = this.nullValueForInitial(property);
        }
      }
    },
    async loadPage() {
        this.setValues();
    },
    async snapShot(){
      this.loadingSnapShot = true;
      try{
        this.saveKioskDetails(this.kioskDetails);
        this.saveDispenseTotalBefore(this.cashDispenseTotalResponse);
        await this.printReceipt(this.API_KEYS_PRINT_LOG.DISPENSER_RECEIPT_NO_UPDATE);
      }catch(e){
        let message = "Unable to print reset confirmation.";
        this.errorToast(message)
      }finally {
        this.loadingSnapShot = false;
      }
    }
  },
  created() {
     this.loadPage();
  },
}
</script>
